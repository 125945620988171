import React, { useEffect, useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Radio, Text } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AccountSelectorComponent, { AccountSelectorContext } from './account_selector/AccountSelectorComponent'
import { ConfirmTransferModal } from './confirmation_modal/ConfirmTransferModal'
import { TransferSuccessComponent } from './TransferSuccessComponent'
import RecentTransfersComponent from './transfer_history/RecentTransfersComponent'
import AddWireCounterpartyModal from './settings/recipients/AddWireCounterpartyModal'
import TransferMethodSelector from './form/method_selector/TransferMethodSelector'
import CheckIssuanceFormSection, { type CheckIssuanceFormState } from './form/checks/CheckIssuanceFormSection'
import RecurringRuleFormSection, { type RecurringRuleFormState }
  from '../../transfer_rules/components/RecurringRuleFormSection'
import TransferRuleConfirmationModal from '../../transfer_rules/components/TransferRuleConfirmationModal'
import {
  needsWireVerification
} from '@/utils/transferUtils'
import { getCurrentDate } from '@/utils/dateUtils'
import { createInsufficientFundsErrorMessage, getAsyncTransferErrorMessage } from '@/utils/errorUtils'
import ErrorBanner from '@/library/errors/ErrorBanner'
import Button, { ButtonSize } from '@/library/button/Button'
import TransferIconFilled from '@/library/icons/TransferIconFilled'
import { Color } from '@/theme/theme'
import { type Account } from '@/types/types'
import { CREATE_TRANSFER } from '@/graphql/mutations/CreateTransfer'
import {
  type CheckEnablementStatus,
  CounterpartyType,
  TransferDirection,
  TransferRuleType,
  TransferType
}
  from '@/graphql/__generated__/globalTypes'
import { type CreateTransferVariables, type CreateTransfer } from '@/graphql/__generated__/CreateTransfer'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import SlideSelect from '@/library/slide_select/SlideSelect'
import PasscodeVerificationModal from '@/library/modal/passcode/PasscodeVerificationModal'
import { isCounterpartyTypeLinkedBankAccount } from '@/utils/financialAccountUtils'
import { useNavigationState } from '@/hooks/useNavigationState'
import { NumberInputSizeVariant } from '@/library/form/number/FormNumberInput'
import PaperPlaneIcon from '@/library/icons/PaperPlaneIcon'

interface TransferPageNavigationState {
  recipientCounterpartyId?: string
  isCheckSendPreSelected?: boolean
}

export interface TransferComponentProps {
  franchiseGroupId: number
  amplifyAccount?: Account
  counterparties?: Account[]
  isRecurringVendorPaymentsEnabled: boolean
  isCheckIssuanceEnabled: boolean
  organizationCheckEnablementStatus?: CheckEnablementStatus
  refetch: () => void
}

export default function TransferComponent (
  {
    franchiseGroupId,
    amplifyAccount,
    counterparties,
    isRecurringVendorPaymentsEnabled,
    isCheckIssuanceEnabled,
    organizationCheckEnablementStatus,
    refetch
  }: TransferComponentProps
): ReactElement {
  const [
    createTransfer,
    { loading: isTransferCreationLoading }
  ] = useMutation<CreateTransfer, CreateTransferVariables>(
    CREATE_TRANSFER, {
      onCompleted: handleTransferSuccess,
      onError: (error) => { handleAsyncTransferError(error) }
    }
  )

  // If url param contains counterpartyId, set as CREDIT and initialize component with CP selected
  const stateOnNavigate = useNavigationState<TransferPageNavigationState>()

  const [preSelectedCounterpartyId, setPreSelectedCounterpartyId] = useState(stateOnNavigate?.recipientCounterpartyId)
  const [transferDirection, setTransferDirection] = useState(getPreselectedTransferDirection(stateOnNavigate))
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>(
    counterparties?.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null
  )

  useEffect(() => {
    setSelectedCounterparty(counterparties?.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null)
  }, [counterparties, preSelectedCounterpartyId])

  const [transferError, setTransferError] = useState<string | null>(null)
  const [amount, setAmount] = useState<string>()
  const [isRecurringTransfer, setIsRecurringTransfer] = useState<boolean>(false)

  const [transferType, setTransferType] = useState<TransferType>(
    stateOnNavigate?.isCheckSendPreSelected === true ? TransferType.CHECK : TransferType.SAMEDAY_ACH
  )

  useEffect(() => {
    if (transferType === TransferType.WIRE) {
      setIsRecurringTransfer(false)
    }
  }, [transferType])

  // If user selects WIRE and flips back to 'Into Amplify', we need to revert this back to ACH.
  useEffect(() => {
    if (transferDirection === TransferDirection.DEBIT) {
      setTransferType(TransferType.SAMEDAY_ACH)
    }
  }, [transferDirection])

  // Transfer completion
  const [isTransferConfirmationModalOpen, setIsTransferConfirmationModalOpen] = useState(false)
  const [isRecurringTransferConfirmationModalOpen, setIsRecurringTransferConfirmationModalOpen] = useState(false)

  const [isTransferComplete, setIsTransferComplete] = useState(false)

  // Passcode verification
  const [isPasscodeVerificationModalOpen, setIsPasscodeVerificationModalOpen] = useState(false)
  const [isPasscodeVerified, setIsPasscodeVerified] = useState(false)

  // Wire Modal
  const [isWireDetailsModalOpen, setIsWireDetailsModalOpen] = useState<boolean>(false)

  // Recurring Transfer State
  const [recurringForm, setRecurringForm] = useState<RecurringRuleFormState>({
    dayOfWeek: 'Monday',
    dayOfMonth: 1,
    transferRuleType: TransferRuleType.DAILY,
    transferDate: getCurrentDate()
  })

  const [checkIssuanceForm, setCheckIssuanceForm] = useState<CheckIssuanceFormState>({
    areCounterpartyCheckFieldsValid: false
  })

  function handleTransferDirectionSelection (value: string): void {
    if (value === TransferDirection.DEBIT || value === TransferDirection.CREDIT) {
      setTransferDirection(value)
    }

    // Non-Plaid accounts can't be DEBIT counterparties. If the user switches transfer direction
    // while a non-Plaid account is selected, we clear the selection
    if (
      value === TransferDirection.DEBIT &&
      selectedCounterparty?.counterparty?.counterpartyType !== CounterpartyType.PLAID) {
      setSelectedCounterparty(null)
    }
  }

  function handleAsyncTransferError (error: ApolloError): void {
    handleTransferError(getAsyncTransferErrorMessage(error, amount ?? null))
  }

  function handleTransferError (message: string): void {
    setTransferError(message)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  async function handleTransferCreation (): Promise<void> {
    const amplifyAccountId = amplifyAccount?.amplifyAccountId
    if (amplifyAccountId == null) {
      handleTransferError('Please make sure that your Amplify Account is selected.'); return
    }
    if (selectedCounterparty?.counterpartyId == null) {
      handleTransferError('Please select a counterparty.'); return
    }

    // Validate amount
    if (amount == null) {
      handleTransferError('Amount is required.'); return
    }
    const amountAsNumber = Number(amount)
    if (transferDirection === TransferDirection.CREDIT) {
      if (amountAsNumber > (
        amplifyAccount?.liveBalance?.availableBalance?.amount ?? 0
      )) {
        handleTransferError(createInsufficientFundsErrorMessage(amountAsNumber)); return
      }
    } else {
      if (amountAsNumber > (selectedCounterparty.liveBalance?.availableBalance?.amount ?? 0)) {
        handleTransferError(createInsufficientFundsErrorMessage(amountAsNumber)); return
      }
    }

    await createTransfer({
      variables: {
        input: {
          amount: Number(amount),
          amplifyAccountId,
          counterpartyId: selectedCounterparty?.counterpartyId,
          direction: transferDirection,
          type: transferType,
          checkMemo: checkIssuanceForm.memo,
          checkMessage: checkIssuanceForm.message
        }
      }
    })
  }

  function handleTransferSuccess (): void {
    setIsTransferComplete(true)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  function handleTransferInitiation (): void {
    if (
      !isCounterpartyTypeLinkedBankAccount(selectedCounterparty?.counterparty?.counterpartyType)
    ) {
      setIsPasscodeVerificationModalOpen(true)
    } else {
      openTransferConfirmationModal()
    }
  }

  function onPasscodeVerificationModalClose (): void {
    setIsPasscodeVerificationModalOpen(false)
    if (isPasscodeVerified) {
      // Setting timeout to avoid jumpy modal to modal transition
      setTimeout(() => {
        openTransferConfirmationModal()
      }, 200)
    }
  }

  function openTransferConfirmationModal (): void {
    // TODO - refactor the flow of passcode + wire + confirmation modals
    if (needsWireVerification(transferType, selectedCounterparty)) {
      setIsWireDetailsModalOpen(true)
    } else if (isRecurringTransfer) {
      setIsRecurringTransferConfirmationModalOpen(true)
    } else {
      setIsTransferConfirmationModalOpen(true)
    }
  }

  function onWireInfoSubmitted (newCounterpartyId: string): void {
    setIsWireDetailsModalOpen(false)
    // Refetch to pull the updated wire info to prevent re-prompting user for same info
    refetch()
    // Reset the selected counterparty to avoid counterparty from reverting to null
    setPreSelectedCounterpartyId(newCounterpartyId)
    setIsTransferConfirmationModalOpen(true)
  }

  function isFormValid (): boolean {
    const isMainFormValid = amount != null && Number(amount) > 0 && selectedCounterparty?.counterpartyId != null &&
           amplifyAccount?.amplifyAccountId != null
    if (!isMainFormValid) {
      return false
    }
    // By default, recurring transfers are only supported to accounts that user owns
    // Users can opt-in to enable recurring payments to vendors (managed by feature flag)
    // TODO Add more clear messaging in UI when recurring disabled due to external counterparty selection
    if (
      isRecurringTransfer &&
      selectedCounterparty.counterparty?.counterpartyType !== CounterpartyType.PLAID &&
      !isRecurringVendorPaymentsEnabled
    ) {
      return false
    }

    // Verify that check fields are filled out when relevant
    if (
      transferType === TransferType.CHECK &&
      (checkIssuanceForm.memo == null || !checkIssuanceForm.areCounterpartyCheckFieldsValid)
    ) {
      return false
    }

    return true
  }

  const fromAccount = transferDirection === TransferDirection.DEBIT ? selectedCounterparty : amplifyAccount
  const toAccount = transferDirection === TransferDirection.DEBIT ? amplifyAccount : selectedCounterparty

  if (isTransferComplete && amount != null) {
    return (
      <TransferSuccessComponent
        amount={Number(amount)}
        fromAccount={fromAccount ?? undefined}
        toAccount={toAccount ?? undefined}
        transferType={transferType}
      />
    )
  }

  const { title: transferButtonTitle, icon: transferButtonIcon } = getTransferButtonProps(transferType)

  return (
    <Flex flexDirection='column' width='100%' justifyContent='center' gap={6}>
      <ErrorBanner
        errorTitle='Problem Making Transfer'
        errorSubTitle={transferError}
      />
      <Heading color={Color.DARK_BLUE} size='md'>Make a Transfer</Heading>
      <SlideSelect
        selectedValue={transferDirection}
        options={[TransferDirection.DEBIT, TransferDirection.CREDIT]}
        handleSelection={handleTransferDirectionSelection}
        formatValue={(value: string) => {
          return value === TransferDirection.CREDIT ? 'Out of Amplify' : 'Into Amplify'
        }}
      />
      <Center flexDir='column' w='100%' gap={4}>
        <FormDollarInput
          fieldName='amount'
          value={amount ?? ''}
          label='Amount'
          onChange={setAmount}
          placeholder='100'
          fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
          backgroundColor={Color.WHITE}
          variant={NumberInputSizeVariant.HERO}
        />
        <TransferMethodSelector
          selectedTransferDirection={transferDirection}
          selectedTransferType={transferType}
          isCheckIssuanceEnabled={isCheckIssuanceEnabled}
          organizationCheckEnablementStatus={organizationCheckEnablementStatus}
          setSelectedTransferType={(type) => { setTransferType(type) }}
        />
        <Flex width='100%' alignItems='center' justifyContent='center'>
          <Flex
            w='100%'
            gap={4}
            flexDirection={transferDirection === TransferDirection.CREDIT ? 'column-reverse' : 'column'}
            transition='flexDirection 0.2s'
          >
            <AccountSelectorComponent
              counterparties={counterparties}
              onSelectedCounterpartyChange={setSelectedCounterparty}
              selectedCounterparty={selectedCounterparty ?? undefined}
              transferDirection={transferDirection}
              context={AccountSelectorContext.TRANSFER}
            />
            <AccountSelectorComponent
              amplifyAccount={amplifyAccount}
              transferDirection={transferDirection}
              context={AccountSelectorContext.TRANSFER}
            />
          </Flex>
        </Flex>
        {
          transferType === TransferType.CHECK && selectedCounterparty != null &&
            <CheckIssuanceFormSection
              formState={checkIssuanceForm}
              onStateUpdate={(state) => { setCheckIssuanceForm(state) }}
              selectedCounterpartyId={selectedCounterparty.counterpartyId ?? null}
            />
        }
        {
          transferType === TransferType.SAMEDAY_ACH &&
            <Flex gap={4} width='100%' padding={2}>
              <Radio
                size='lg'
                isChecked={isRecurringTransfer}
                colorScheme='selectableInput'
                onClick={() => { setIsRecurringTransfer(!isRecurringTransfer) }}
              />
              <Text> Make this a recurring transfer </Text>
            </Flex>
        }
        {
          isRecurringTransfer &&
            <RecurringRuleFormSection
              formState={recurringForm}
              setState={setRecurringForm}
            />
        }
      </Center>
      <Flex width='100%'>
        <Button
          text={transferButtonTitle}
          onClick={handleTransferInitiation}
          beforeIcon={transferButtonIcon}
          isDisabled={!isFormValid() || isTransferCreationLoading}
          isLoading={isTransferCreationLoading}
          size={ButtonSize.LARGE}
          onClickEventType={!isRecurringTransfer ? Event.INITIATE_TRANSFER_CLICK : Event.TRANSFER_PAGE_CREATE_RULE}
        />
      </Flex>
      <Flex width='100%' my={16}>
        <RecentTransfersComponent franchiseGroupId={franchiseGroupId}/>
      </Flex>
      <ConfirmTransferModal
        isOpen={isTransferConfirmationModalOpen}
        onClose={() => { setIsTransferConfirmationModalOpen(false) }}
        amount={Number(amount)}
        fromAccount={fromAccount ?? undefined}
        toAccount={toAccount ?? undefined}
        onConfirm={handleTransferCreation}
        isTransferCreationLoading={isTransferCreationLoading}
        transferDirection={transferDirection}
        transferType={transferType}
      />
      <TransferRuleConfirmationModal
        isModalOpen={isRecurringTransferConfirmationModalOpen}
        onModalClose={() => { setIsRecurringTransferConfirmationModalOpen(false) }}
        onUnhandledSubmissionError={() => {}}
        ruleData={{
          amplifyAccount,
          counterparty: selectedCounterparty ?? undefined,
          transferRuleType: recurringForm.transferRuleType,
          direction: transferDirection,
          transferAmount: amount,
          targetDate:
            recurringForm.transferRuleType === TransferRuleType.MONTHLY ? recurringForm.dayOfMonth : undefined,
          targetDay: recurringForm.transferRuleType === TransferRuleType.WEEKLY ? recurringForm.dayOfWeek : undefined,
          ruleStartDate: recurringForm.transferDate
        }}
      />
      <PasscodeVerificationModal
        isOpen={isPasscodeVerificationModalOpen}
        onClose={onPasscodeVerificationModalClose}
        onVerificationStatusChange={(isVerified) => { setIsPasscodeVerified(isVerified) }}
      />
      <AddWireCounterpartyModal
        isOpen={isWireDetailsModalOpen}
        onSuccess={(newCounterpartyId) => {
          onWireInfoSubmitted(newCounterpartyId)
        }}
        onClose={() => { setIsWireDetailsModalOpen(false) }}
        counterpartyId={selectedCounterparty?.counterpartyId ?? ''}
        achAccountNumber={selectedCounterparty?.achAccountNumber ?? ''}
        achRoutingNumber={selectedCounterparty?.achRoutingNumber ?? ''}
      />
    </Flex>
  )
}

function getTransferButtonProps (transferType: TransferType): { title: string, icon: ReactElement } {
  if (transferType === TransferType.CHECK) {
    return {
      title: 'Send Check',
      icon: <PaperPlaneIcon/>
    }
  }

  return {
    title: 'Make Transfer',
    icon: <TransferIconFilled color={Color.WHITE}/>
  }
}

function getPreselectedTransferDirection (navigationState: TransferPageNavigationState): TransferDirection {
  if (navigationState?.recipientCounterpartyId != null) {
    return TransferDirection.CREDIT
  }

  if (navigationState?.isCheckSendPreSelected === true) {
    return TransferDirection.CREDIT
  }

  return TransferDirection.DEBIT
}
