import React, { type ReactElement } from 'react'
import { Flex, Tooltip } from '@chakra-ui/react'
import ChooseAnAccountContent from './ChooseAnAccountContent'
import AccountRowContent from './AccountRowContent'
import { BorderRadius, Color } from '@/theme/theme'
import { type Account } from '@/types/types'

export interface AccountRowProps {
  account?: Account
  isSelected?: boolean
  isDisabled?: boolean
  hideDisabledState?: boolean
  isExternalTransfersEnabled?: boolean
  tooltipLabel?: string
  onClick: (account?: Account) => void
}

export function SelectableAccountRow (
  {
    account: nullableAccount,
    isExternalTransfersEnabled = false,
    isSelected = false,
    isDisabled = false,
    hideDisabledState = false,
    tooltipLabel,
    onClick
  }: AccountRowProps
): ReactElement {
  const border = isSelected ? `1.5px solid ${String(Color.DARK_BLUE)}` : ''

  return (
    <Tooltip
      label={tooltipLabel}
      bg={Color.DARK_BLUE}
      hasArrow={true}
    >
      <Flex
        bg={Color.WHITE}
        border={border}
        borderRadius={BorderRadius.CARD}
        p={6}
        width='100%'
        _hover={{
          cursor: !isDisabled ? 'pointer' : hideDisabledState ? 'auto' : 'not-allowed',
          backgroundColor: isDisabled ? Color.WHITE : Color.GREY
        }}
        alignItems='center'
        onClick={() => {
          !isDisabled && onClick()
        }}
      >
        {nullableAccount == null
          ? <ChooseAnAccountContent/>
          : <AccountRowContent
              account={nullableAccount}
              isDisabled={isDisabled}
              isExternalTransfersEnabled={isExternalTransfersEnabled}
              hideDisabledState={hideDisabledState}
            />}
      </Flex>
    </Tooltip>
  )
}
