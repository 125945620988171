import React, { type ReactElement, type ReactNode } from 'react'
import { Badge as BaseBadge, Flex, type BadgeProps as BaseBadgeProps } from '@chakra-ui/react'
import { FontWeight, Color } from '@/theme/theme'

export enum BadgeVariant {
  OUTLINE,
  GREY,
  RED
}

interface BadgeProps extends BaseBadgeProps {
  children: ReactNode
  badgeVariant?: BadgeVariant
}

export default function Badge ({ children, badgeVariant = BadgeVariant.GREY, ...props }: BadgeProps): ReactElement {
  const styleProps = getStyleProps(badgeVariant)
  return (
    <Flex>
      <BaseBadge
        justifyContent='center'
        textTransform='none'
        fontSize='sm'
        px={3}
        {...styleProps}
        {...props}
        width='auto'
      >
        {children}
      </BaseBadge>
    </Flex>
  )
}

function getStyleProps (variant: BadgeVariant): BaseBadgeProps {
  switch (variant) {
    case BadgeVariant.OUTLINE:{
      return {
        bg: Color.WHITE,
        color: Color.DARK_GREY,
        border: '1px',
        borderColor: Color.DARK_GREY,
        fontWeight: FontWeight.MEDIUM
      }
    }
    case BadgeVariant.GREY:{
      return {
        bg: Color.GREY,
        color: Color.DARK_GREY,
        fontWeight: FontWeight.MEDIUM
      }
    }
    case BadgeVariant.RED:{
      return {
        bg: Color.WHITE,
        color: Color.ERROR_RED,
        border: '1px',
        borderColor: Color.ERROR_RED,
        fontWeight: FontWeight.MEDIUM
      }
    }
  }
}
