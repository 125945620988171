import { gql } from '@apollo/client'
import {
  AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT
} from '../fragments/amplify_account_application/AmplifyAccountApplicationFragment'
import { ADDRESS_FRAGMENT } from '../fragments/AddressFragment'

export const GET_AMPLIFY_OWNER_DATA = gql`
  ${AMPLIFY_ACCOUNT_APPLICATION_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  query GetAmplifyOwnerData($franchiseGroupId: Int!) {
    currentUser {
      franchiseGroup(id: $franchiseGroupId) {
        amplifyAccount {
          amplifyAccount {
            people {
              treasuryPrimeId
              firstName
              middleName
              lastName
              mailingAddress {
                ...AddressFragment
              }
              email
              phoneNumber
              physicalAddress {
                ...AddressFragment
              }
              occupation
              title
              ownershipPercentage
              dateOfBirth
              citizenship
            }
            business {
              treasuryPrimeId
              emails
              mailingAddress {
                ...AddressFragment
              }
              name
              phoneNumber
              legalName
              doingBusinessAs
              description
              establishmentDate
              incorporationState
              legalStructure
              url
            }
          }
        }
        amplifyAccountApplication {
          ...AmplifyAccountApplicationFragment
        }
      }
    }
  }
`
