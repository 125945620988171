import { gql } from '@apollo/client'

export const GET_HOME_PAGE_DATA = gql`
    query GetHomePageData($organizationId: String, $financialAccountFilter: FinancialAccountFilterInput) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                name
                isOnAmplifyWaitlist
                franchiseGroups {
                    id
                    name
                    amplifyAccountApplicationStatus
                    financialAccounts(financialAccountFilter: $financialAccountFilter) {
                        accountId
                        name
                        lastFour
                        businessName
                        liveBalance {
                            availableBalance {
                                amount
                            }
                        }
                        amplifyAccount {
                            id
                            earningsToDate {
                                amount
                            }
                        }
                    }
                    aggregateAccountBalance(financialAccountFilter: $financialAccountFilter) {
                        availableBalance {
                            amount
                        }
                    }
                }
                users {
                    firstName
                    lastName
                }
            }
        }
    }  
`
