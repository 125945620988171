import React, { useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  filterCounterparties,
  getCounterpartyTypeOptions,
  type AccountWithDisabledState,
  getAccountRowTooltipText,
  sortCounterparties
} from './utils'
import { SelectableAccountRow } from './account_row/SelectableAccountRow'
import { AccountSelectorContext } from './AccountSelectorComponent'
import EmptyAccountsSectionComponent from '../../../treasury/components/treasury/EmptyAccountsSectionComponent'
import { IconSize } from '@/theme/theme'
import { type Account } from '@/types/types'
import { TransferDirection } from '@/graphql/__generated__/globalTypes'
import SearchBar from '@/library/form/text/SearchBar'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import Button, { ButtonVariant } from '@/library/button/Button'
import CirclePlusOutlineIcon from '@/library/icons/CirclePlusOutlineIcon'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { ROUTES } from '@/api/routes'

export enum CounterpartyTypeSearchCriteria {
  ALL = 'All',
  VENDOR = 'Vendor',
  ACCOUNT = 'Bank Account'
}

export interface AccountSelectorModalProps {
  isOpen: boolean
  onClose: () => void
  onSelectCounterParty: (counterparty: Account) => void
  counterparties?: AccountWithDisabledState[]
  selectedCounterparty?: Account
  transferDirection?: TransferDirection
  refetch: () => void
  context: AccountSelectorContext
}

export function AccountSelectorModal (
  {
    isOpen,
    onClose,
    counterparties = [],
    onSelectCounterParty,
    transferDirection,
    refetch,
    context
  }: AccountSelectorModalProps
): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const navigate = useNavigate()
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>()
  const [searchQuery, setSearchQuery] = useState('')
  const [counterpartyTypeFilter, setCounterpartyTypeFilter] = useState(CounterpartyTypeSearchCriteria.ALL)

  function handleCounterpartySelection (counterparty: Account): void {
    setSelectedCounterparty(counterparty)
    setSearchQuery('')
    onSelectCounterParty(counterparty)
  }

  const transferDirectionHeaderText = transferDirection === TransferDirection.CREDIT ? 'To' : 'From'
  const transferDirectionBodyText = transferDirectionHeaderText.toLowerCase()

  // Allow users to add new recipients if
  //   1. They surface this modal on the transfer page
  //   2. They are initiating a transfer OUT OF the Amplify Account
  const canAddNewExternalRecipient = context === AccountSelectorContext.TRANSFER &&
    transferDirection === TransferDirection.CREDIT

  const filteredCounterparties = sortCounterparties(filterCounterparties(
    counterparties,
    counterpartyTypeFilter,
    searchQuery
  ))

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => { setSearchQuery(''); onClose() }}
      size='2xl'
      trapFocus={false}
    >
      <Center flexDirection='column' gap={8}>
        <Center flexDir='column' w='100%' gap={2}>
          <Heading size='lg'>
            Transfer {transferDirectionHeaderText}
          </Heading>
          <Text>
            Select which account to transfer {transferDirectionBodyText}
          </Text>
        </Center>
        {
          canAddNewExternalRecipient &&
            <Button
              text='Add a New Recipient'
              beforeIcon={<CirclePlusOutlineIcon size={IconSize.SCHMEDIUM}/>}
              variant={ButtonVariant.WHITE_OUTLINE}
              width='auto'
              onClick={() => { navigate(ROUTES.RECIPIENTS_CREATE, { state: { isOriginTransferPage: true } }) }}
            />
        }
        <Center flexDir='column' w='100%' gap={4}>
          <Flex w='100%' gap={4}>
            <SearchBar queryValue={searchQuery} onChange={setSearchQuery}/>
            <SelectorComponent
              value={counterpartyTypeFilter}
              options={getCounterpartyTypeOptions(transferDirection)}
              alignText='start'
              handleSelection={(value) => { setCounterpartyTypeFilter(value) }}
            />
          </Flex>
          {
            counterparties.length < 1
              ? <EmptyAccountsSectionComponent
                  franchiseGroupId={selectedFranchiseGroupId}
                  ctaText='Connect your accounts in order to make a transfer.'
                  refetch={refetch}
                />
              : <Flex flexDirection='column' width='100%' gap={2}>
                {
                  filteredCounterparties?.map((counterparty, index) =>
                    <SelectableAccountRow
                      key={index}
                      onClick={() => { handleCounterpartySelection(counterparty) }}
                      account={counterparty}
                      isSelected={counterparty.counterpartyId === selectedCounterparty?.counterpartyId}
                      isDisabled={counterparty.enabledState.isDisabled}
                      tooltipLabel={getAccountRowTooltipText(counterparty)}
                    />
                  )
                }
              </Flex>
          }
        </Center>
      </Center>
    </ModalComponent>
  )
}
