import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from './financial_accounts/FinancialAccountFragment'

export const TRANSFER_FRAGMENT = gql`
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    fragment TransferFragment on Transfer {
        id
        amount {
            amount
            amountFormatted
        }
        createdAt
        description
        direction
        status
        type
        effectiveDate
        checkMemo
        checkMessage
        eligibleCancellationType
        counterparty {
            ...FinancialAccountFragment
        }
        amplifyAccount {
                ...FinancialAccountFragment
        }
    }
`
