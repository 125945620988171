import React, { type ReactElement } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Event } from 'metrics/metrics'
import { type Account } from '@/types/types'
import { getMask, isLinkedBankAccountOrAmplifyAccount } from '@/utils/financialAccountUtils'
import { Color } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { AccountLinkStatus } from '@/graphql/__generated__/globalTypes'
import ClockIcon from '@/library/icons/ClockIcon'
import InstitutionLogo from '@/library/logo/InstitutionLogo'
import CircleCheckIcon from '@/library/icons/CircleCheckIcon'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import { ROUTES } from '@/api/routes'
import { DateTimeTemplate, getFormattedDateString, getFormattedTimeString } from '@/utils/dateUtils'
import { isAltirConnectAccount } from '@/utils/transferUtils'

interface AccountRowContentProps {
  account: Account
  isExternalTransfersEnabled: boolean
  isDisabled: boolean
  hideDisabledState?: boolean
}

export default function AccountRowContent ({
  account,
  isExternalTransfersEnabled,
  isDisabled,
  hideDisabledState = false
}: AccountRowContentProps): ReactElement {
  const navigate = useNavigate()

  function getIcon (account: Account): ReactElement {
    const isAwaitingVerification = isExternalTransfersEnabled && account?.counterparty?.isOwnershipVerified === false
    if (isAwaitingVerification) {
      return (
        <ClockIcon color={Color.DARK_GREY}/>
      )
    } else if (account?.institution?.logoAssetUrl != null) {
      return (<InstitutionLogo
        src={account.institution.logoAssetUrl}
        height='25px'
              />
      )
    }
    return (
      <CircleCheckIcon color={Color.DARK_BLUE}/>
    )
  }

  function getRowTextContent (): ReactElement {
    if (isLinkedBankAccountOrAmplifyAccount(account)) {
      return (
        <Flex alignItems='center' w='100%' gap={3}>
          <Heading opacity={opacity} size='sm' color={Color.DARK_BLUE}>
            {getMask(account.lastFour)}
          </Heading>
          <Heading opacity={opacity} size='sm' color={Color.DARK_BLUE}>{account?.institution?.name}</Heading>
          <Heading opacity={opacity} size='sm' color={Color.DARK_BLUE}>{account.name}</Heading>
        </Flex>
      )
    }

    return (
      <Flex alignItems='center' w='100%' gap={3}>
        <Heading opacity={opacity} size='sm' color={Color.DARK_BLUE}>{account.counterparty?.nickname}</Heading>
        <Heading opacity={opacity} size='sm' color={Color.DARK_GREY}>|</Heading>
        <Heading opacity={opacity} size='sm' color={Color.DARK_GREY}>{account.counterparty?.nameOnAccount}</Heading>
        <Heading opacity={opacity} size='sm' color={Color.DARK_GREY}>
          {getMask(account.counterparty?.achAccountNumber ?? null)}
        </Heading>
      </Flex>
    )
  }

  const isAccountLoginRequired = account.status === AccountLinkStatus.LOGIN_REQUIRED
  const opacity = isDisabled && !hideDisabledState ? 0.25 : 1

  const shouldShowBalanceUpdatedAt = isAltirConnectAccount(account) && account.liveBalance?.availableBalance != null
  return (
    <Flex flexDirection='row' alignItems='center' justifyContent='start' gap={3} width='100%'>
      <Box minW='30px' opacity={opacity}>
        {getIcon(account)}
      </Box>
      {getRowTextContent()}
      <Flex
        flexDirection='row'
        ml='auto'
        alignItems='center'
        justifyContent='center'
        gap={3}
        whiteSpace='nowrap'
      >
        {isAccountLoginRequired && <Button
          variant={ButtonVariant.DESTRUCTIVE}
          onClick={() => { navigate(ROUTES.SETTINGS, { state: { isLinkedAccountsSelected: true } }) }}
          text='Reconnect'
          size={ButtonSize.SMALL}
          onClickEventType={Event.PLAID_RELINK_OPEN_CLICK}
                                   />}
        <Flex flexDir='column' justifyContent='center' textAlign='end'>
          <Heading size='sm' opacity={opacity}>
            {isLinkedBankAccountOrAmplifyAccount(account)
              ? account.liveBalance?.availableBalance?.amountFormatted ?? EMPTY_BALANCE_VALUE
              : ''}
          </Heading>
          {shouldShowBalanceUpdatedAt && <Text fontSize='xs'>
            {`Updated at ${getFormattedTimeString(account.liveBalance?.updatedAt) ?? ''} ${getFormattedDateString(account.liveBalance?.updatedAt, DateTimeTemplate.MONTH_YEAR_SHORT) ?? ''}`}
            </Text>}
        </Flex>
      </Flex>
    </Flex>
  )
}
