import React, { type ReactElement } from 'react'
import { Center, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import TransferComponent from './TransferComponent'
import RecipientsCard from './settings/recipients/RecipientsCard'
import AmplifyInfoComponent from './amplify_info/AmplifyInfoComponent'
import CounterpartiesPendingVerificationCard from './settings/recipients/CounterpartiesPendingVerificationCard'
import CreatedAutomatedTransferButton from './settings/CreateAutomatedTransferButton'
import ViewAutomatedTransferRulesCard from './settings/ViewAutomatedTransferRulesCard'
import { type Account } from '@/types/types'
import PasscodeCreationModal from '@/library/modal/passcode/PasscodeCreationModal'
import {
  type GetCounterpartiesPendingVerification,
  type GetCounterpartiesPendingVerificationVariables
} from '@/graphql/__generated__/GetCounterpartiesPendingVerification'
import { GET_COUNTERPARTIES_PENDING_VERIFICATION } from '@/graphql/queries/GetRecipientsPageData'
import { type CheckEnablementStatus, CounterpartyType } from '@/graphql/__generated__/globalTypes'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'
import { ContainerWidth } from '@/theme/theme'

interface TransferContainerComponentProps {
  franchiseGroupId: number
  amplifyAccount?: Account
  counterparties?: Account[]
  doesUserHavePasscode?: boolean
  isRecurringVendorPaymentsEnabled: boolean
  isCheckIssuanceEnabled: boolean
  organizationCheckEnablementStatus?: CheckEnablementStatus
  refetch: () => void
}

export default function TransferContainerComponent ({
  franchiseGroupId,
  amplifyAccount,
  counterparties,
  doesUserHavePasscode,
  isRecurringVendorPaymentsEnabled,
  isCheckIssuanceEnabled,
  organizationCheckEnablementStatus,
  refetch
}: TransferContainerComponentProps): ReactElement {
  const {
    data: unverifiedCounterpartyData,
    loading: unverifiedCounterpartyLoading,
    error: unverifiedCounterpartyError,
    refetch: refetchUnverifiedCounterpartyData
  } = useQuery<GetCounterpartiesPendingVerification, GetCounterpartiesPendingVerificationVariables>(
    GET_COUNTERPARTIES_PENDING_VERIFICATION,
    {
      variables: {
        franchiseGroupId,
        isOwnershipVerified: false,
        counterpartyTypes: [CounterpartyType.EXTERNAL_PERSONAL, CounterpartyType.PLAID]
      },
      fetchPolicy: 'network-only'
    }
  )

  function refetchAllData (): void {
    refetch()
    void refetchUnverifiedCounterpartyData()
  }

  return (
    <Center w='100%'>
      <Flex gap={6} w='100%' maxW={ContainerWidth.FULL_PAGE}>
        {doesUserHavePasscode === false && <PasscodeCreationModal/>}
        <TransferComponent
          franchiseGroupId={franchiseGroupId}
          amplifyAccount={amplifyAccount}
          counterparties={counterparties}
          isRecurringVendorPaymentsEnabled={isRecurringVendorPaymentsEnabled}
          isCheckIssuanceEnabled={isCheckIssuanceEnabled}
          organizationCheckEnablementStatus={organizationCheckEnablementStatus}
          refetch={refetchAllData}
        />
        <Flex flexDir='column' gap={6}>
          <BusinessSelectorComponent
            businessFilterParams={{ hasAmplify: true }}
            requireMultipleBusinesses={true}
          />
          <Flex flexDir='column' gap={3}>
            <Text>Transfer Settings</Text>
            <ViewAutomatedTransferRulesCard/>
            <AmplifyInfoComponent/>
            <RecipientsCard franchiseGroupId={franchiseGroupId}/>
            <CounterpartiesPendingVerificationCard
              data={unverifiedCounterpartyData}
              loading={unverifiedCounterpartyLoading}
              error={unverifiedCounterpartyError}
            />
            <CreatedAutomatedTransferButton/>
          </Flex>
        </Flex>
      </Flex>
    </Center>
  )
}
