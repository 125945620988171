import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import TransferContainerComponent from './components/TransferContainerComponent'
import {
  convertCounterpartiesToAccountObjects,
  convertAmplifyAccountToAccountObject
} from '@/utils/transferUtils'
import {
  type GetTransferPageData,
  type GetTransferPageDataVariables
} from '@/graphql/__generated__/GetTransferPageData'
import { GET_TRANSFER_PAGE_DATA } from '@/graphql/queries/GetTransferPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function TransferPage (): ReactElement {
  // TODO (PJ): Remove this query and update main query to pull from current user
  const { franchiseGroupId, organizationId } = useAltirStore(state => {
    return {
      franchiseGroupId: state.selectedFranchiseGroupId,
      organizationId: state.selectedOrganizationState.selectedOrganizationId
    }
  })
  const {
    data,
    loading: pageLoading,
    error: pageDataError,
    refetch
  } = useQuery<GetTransferPageData, GetTransferPageDataVariables>(
    GET_TRANSFER_PAGE_DATA,
    {
      variables: {
        franchiseGroupId,
        organizationId
      },
      fetchPolicy: 'network-only'
    }
  )

  const amplifyAccount = convertAmplifyAccountToAccountObject(data?.amplifyAccount ?? undefined)
  const counterparties = convertCounterpartiesToAccountObjects(data?.counterparties)

  // Rollout
  const rolloutConfiguration = data?.currentUser?.rolloutConfiguration
  const isRecurringVendorPaymentsEnabled = rolloutConfiguration?.enableRecurringVendorPayments === true
  const isCheckIssuanceEnabled = rolloutConfiguration?.enableCheckIssuance === true

  const checkEnablementStatus = data?.currentUser?.selectedOrganization?.checkEnablementStatus ?? undefined

  return (
    <AltirSkeleton
      isLoading={pageLoading || franchiseGroupId == null}
      error={pageDataError}
      width='100%'
    >
      <TransferContainerComponent
        franchiseGroupId={franchiseGroupId}
        amplifyAccount={amplifyAccount}
        counterparties={counterparties}
        doesUserHavePasscode={data?.currentUser?.hasPasscode ?? undefined}
        isRecurringVendorPaymentsEnabled={isRecurringVendorPaymentsEnabled}
        isCheckIssuanceEnabled={isCheckIssuanceEnabled}
        organizationCheckEnablementStatus={checkEnablementStatus}
        refetch={refetch}
      />
    </AltirSkeleton>
  )
}
