import { gql } from '@apollo/client'
import { COUNTERPARTY_FRAGMENT } from './CounterpartyFragment'

export const FINANCIAL_ACCOUNT_FRAGMENT = gql`
    ${COUNTERPARTY_FRAGMENT}
    fragment FinancialAccountFragment on FinancialAccount {
        accountId
        counterpartyId
        franchiseGroupId
        name
        accountType
        accountSubTypeFormatted
        lastFour
        achAccountNumber
        achRoutingNumber
        institution {
            name
            logoAssetUrl
        }
        transactionsUpdatedAt
        status
        connectionProvider
        plaidItemId
        plaidAccessToken
        counterparty {
            ...CounterpartyFragment
        }
        amplifyAccount {
            id
        }
    }
`
