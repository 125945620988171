import { CounterpartyTypeSearchCriteria } from './AccountSelectorModal'
import { AccountLinkStatus, CounterpartyType, TransferDirection } from '@/graphql/__generated__/globalTypes'
import { type Account } from '@/types/types'
import {
  isAccountValidForDebit,
  isCounterpartyTypeOwnedBankAccount
} from '@/utils/financialAccountUtils'

export const COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW = `
  This account is being verified. Any transfers you initiate may be delayed until the verification process is complete.
`

export const DISABLED_REASON_EXTERNAL_COUNTERPARTY_CREDIT = `
  We cannot initiate transfers from this account. Please try another one or change the transfer direction.
`

export const DISABLED_REASON_PLAID_COUNTERPARTY_DISCONNECTED = `
  We can't initiate transfers from this account until it has been re-linked.
`

export interface AccountWithEnabledState {
  isDisabled: boolean
  disabledReason?: string
}

export type AccountWithDisabledState = Account & { enabledState: AccountWithEnabledState }

export function getCounterpartyTypeOptions (transferDirection?: TransferDirection): CounterpartyTypeSearchCriteria[] {
  return transferDirection === TransferDirection.CREDIT
    ? [
        CounterpartyTypeSearchCriteria.ALL,
        CounterpartyTypeSearchCriteria.ACCOUNT,
        CounterpartyTypeSearchCriteria.VENDOR
      ]
    : [CounterpartyTypeSearchCriteria.ACCOUNT]
}

export function augmentAccountsWithDisabledStates (
  accounts: Account[],
  transferDirection?: TransferDirection
): AccountWithDisabledState[] {
  return accounts.map(account => {
    return {
      ...account,
      enabledState: getAccountSelectorStateForAccount(account, transferDirection)
    }
  })
}

/**
 * Returns whether a given account is valid for selection given the broader transfer context.
 * In the event that an account is disabled, also provides a specific reason as to why the
 * account cannot be selected
 */
function getAccountSelectorStateForAccount (
  account: Account,
  transferDirection?: TransferDirection
): AccountWithEnabledState {
  // Vendors + external personal are invalid for DEBIT
  if (
    transferDirection === TransferDirection.DEBIT &&
      !isAccountValidForDebit(account)
  ) {
    return {
      isDisabled: true,
      disabledReason: DISABLED_REASON_EXTERNAL_COUNTERPARTY_CREDIT
    }
  }

  // Broken Plaid accounts are invalid for DEBIT as we can't access their balance
  if (
    transferDirection === TransferDirection.DEBIT &&
      account.status === AccountLinkStatus.LOGIN_REQUIRED
  ) {
    return {
      isDisabled: true,
      disabledReason: DISABLED_REASON_PLAID_COUNTERPARTY_DISCONNECTED
    }
  }

  return { isDisabled: false }
}

/**
 * If the counterparty isn't valid for the selected counterparty critera, explain why.
 *
 *
 */
export function getAccountRowTooltipText (account: AccountWithDisabledState): string | undefined {
  if (account.enabledState.disabledReason != null) return account.enabledState.disabledReason

  return account.counterparty?.isOwnershipVerified === true
    ? undefined
    : COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW
}

/**
 * Filters counterparties based off of user search input
 * DOES NOT hide counterparties that are invalid for a given transfer type
 */
export function filterCounterparties (
  counterparties: AccountWithDisabledState[],
  type: CounterpartyTypeSearchCriteria,
  searchQuery: string
): AccountWithDisabledState[] {
  return counterparties
    // Filter based on selected type
    .filter(counterparty => {
      const counterpartyType = counterparty.counterparty?.counterpartyType
      if (type === CounterpartyTypeSearchCriteria.VENDOR) {
        return counterpartyType === CounterpartyType.EXTERNAL_VENDOR
      } else if (type === CounterpartyTypeSearchCriteria.ACCOUNT) {
        return isCounterpartyTypeOwnedBankAccount(counterpartyType)
      }
      return true
    })
    // Filter based on search query text
    .filter(counterparty => {
      const formattedQuery = searchQuery.toLowerCase()
      return counterparty.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.institution?.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.lastFour?.includes(formattedQuery) === true ||
        counterparty.counterparty?.nickname?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.achAccountNumber?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.nameOnAccount?.toLocaleLowerCase().includes(formattedQuery) === true
    })
}

/**
 * Sorts counterparties for the AccountSelectorModal based on the following criteria:
 * 1. Valid counterparties before disabled ones
 * 2. Alphabetically by name
 */
export function sortCounterparties (
  counterparties: AccountWithDisabledState[]
): AccountWithDisabledState[] {
  return counterparties.sort((a, b) => {
    // 1. Sort by validity
    if (a.enabledState.isDisabled !== b.enabledState.isDisabled) {
      return Number(a.enabledState.isDisabled) - Number(b.enabledState.isDisabled)
    }

    // 2. Fall back to name
    return a.name?.localeCompare(b.name ?? '') ?? 0
  })
}
