export const ROOT = 'home.altir.app'

export enum RouteName {
  ACCOUNTS = 'accounts',
  AMPLIFY_APP = 'amplify_application',
  CARDS = 'cards',
  CAPTIAL_OS_CARDS = 'cards/altir',
  CHECKS = 'checks',
  ALTIR = 'altir',
  ERROR = 'error',
  HOME = 'home',
  INSIGHTS = 'insights',
  LOANS = 'loans',
  LOG_IN = 'log_in',
  RECIPIENTS = 'recipients',
  SETTINGS = 'settings',
  SIGN_UP_SUCCESS = 'sign_up/success',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  TRANSFER = 'transfer',
  TRANSFER_HISTORY = 'transfer_history',
  TREASURY = 'treasury',
  TRANSACTIONS = 'transactions',
  AUTOMATED_TRANSFERS = 'automated_transfers',
  CREATE_AUTOMATED_TRANSFER_RULE = 'automated_transfers/create',
  NOT_AUTHORIZED = 'not_authorized',
  NOT_FOUND = 'not_found',
  V2_SIGN_UP = 'v2/sign_up',
  RESET_STATE = 'reset_state'
}

export enum RouteParam {
  ACCOUNT_ID = 'ACCOUNT_ID',
  AMPLIFY_APPLICATION_ID = 'AMPLIFY_APPLICATION_ID',
  BUSINESS_ID = 'BUSINESS_ID',
  BUSINESS_APPLICATION_ID = 'BUSINESS_APPLICATION_ID',
  PERSON_ID = 'PERSON_ID'
}

export const ROUTES = {
  // Amplify Application
  AMPLIFY_APP_OVERVIEW: `/${RouteName.AMPLIFY_APP}/overview`,
  AMPLIFY_APP_APPLY: `/${RouteName.AMPLIFY_APP}/apply`,
  AMPLIFY_APP_OWNERSHIP: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/ownership/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  AMPLIFY_APP_PERSON: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/person/:${RouteParam.PERSON_ID}`,
  AMPLIFY_APP_PERSON_CONFIRMATION: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/person_confirmation/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  AMPLIFY_APP_BUSINESS: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/business/:${RouteParam.BUSINESS_APPLICATION_ID}`,
  AMPLIFY_APP_USAGE: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/usage/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  AMPLIFY_APP_DISCLOSURES: `/${RouteName.AMPLIFY_APP}/apply/:${RouteParam.BUSINESS_ID}/disclosures/:${RouteParam.AMPLIFY_APPLICATION_ID}`,

  ACCOUNT: `/${RouteName.ACCOUNTS}`,
  ACCOUNT_DETAIL: `/${RouteName.ACCOUNTS}/:${RouteParam.ACCOUNT_ID}`,
  CHECKS: `/${RouteName.ACCOUNTS}/:${RouteParam.ACCOUNT_ID}/${RouteName.CHECKS}`,
  AUTOMATED_TRANSFERS: `/${RouteName.AUTOMATED_TRANSFERS}`,
  CARDS: `/${RouteName.CARDS}`,
  CARDS_DETAIL: `/${RouteName.CARDS}/:id`,
  CAPITAL_OS_CARDS: `/${RouteName.CAPTIAL_OS_CARDS}/:id`,
  CREATE_AUTOMATED_TRANSFER_RULE: `/${RouteName.CREATE_AUTOMATED_TRANSFER_RULE}`,
  ERROR: `/${RouteName.ERROR}`,
  HOME: `/${RouteName.HOME}`,
  INSIGHTS: `/${RouteName.INSIGHTS}`,
  INDEX: '/',
  LOANS: `/${RouteName.LOANS}`,
  LOG_IN: `/${RouteName.LOG_IN}`,
  NOT_AUTHORIZED: `/${RouteName.NOT_AUTHORIZED}`,
  NOT_FOUND: `/${RouteName.NOT_FOUND}`,
  RECIPIENTS: `/${RouteName.RECIPIENTS}`,
  RECIPIENTS_CREATE: `/${RouteName.RECIPIENTS}/create`,
  RESET_STATE: `${RouteName.RESET_STATE}`,
  SETTINGS: `/${RouteName.SETTINGS}`,
  SIGN_UP_SUCCESS: `/${RouteName.SIGN_UP_SUCCESS}`,
  TERMS_AND_CONDITIONS: `/${RouteName.TERMS_AND_CONDITIONS}`,
  TRANSFER: `/${RouteName.TRANSFER}`,
  TRANSFER_HISTORY: `/${RouteName.TRANSFER_HISTORY}`,
  TREASURY: `/${RouteName.TREASURY}`,
  TREASURY_BUSINESS: `/${RouteName.TREASURY}/business/:id`,
  TREASURY_DETAIL: `/${RouteName.TREASURY}/:id`,
  TRANSACTIONS: `/${RouteName.TRANSACTIONS}`,

  // Onboarding V2 //
  V2_SIGN_UP: `/${RouteName.V2_SIGN_UP}`,
  V2_SIGN_UP_USER: `/${RouteName.V2_SIGN_UP}/user`,
  V2_SIGN_UP_EMAIL_VERIFICATION: `/${RouteName.V2_SIGN_UP}/email_verification`,
  V2_SIGN_UP_EMAIL_VERIFICATION_VERIFY: `/${RouteName.V2_SIGN_UP}/email_verification/verify`,
  V2_SIGN_UP_ORGANIZATION_CREATE: `/${RouteName.V2_SIGN_UP}/organization/create`,
  V2_SIGN_UP_ORGANIZATION_ACCEPT: `/${RouteName.V2_SIGN_UP}/organization/accept`,
  V2_SIGN_UP_BUSINESS: `/${RouteName.V2_SIGN_UP}/business`,
  V2_SIGN_UP_BUSINESS_INTRO: `/${RouteName.V2_SIGN_UP}/business/intro`,
  V2_SIGN_UP_BUSINESS_CREATE: `/${RouteName.V2_SIGN_UP}/business/create`,
  V2_SIGN_UP_BUSINESS_CREATE_STORE_LOCATION: `/${RouteName.V2_SIGN_UP}/business/:${RouteParam.BUSINESS_ID}/$store_location/create`,
  V2_SIGN_UP_PRO: `/${RouteName.V2_SIGN_UP}/pro`,
  V2_SIGN_UP_SUCCESS: `/${RouteName.V2_SIGN_UP}/success`,
  // Amplify
  V2_SIGN_UP_AMPLIFY_INTRO: `/${RouteName.V2_SIGN_UP}/amplify/intro`,
  V2_SIGN_UP_AMPLIFY_OVERVIEW: `/${RouteName.V2_SIGN_UP}/amplify/overview`,
  V2_SIGN_UP_AMPLIFY_APP: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_OWNERSHIP: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/ownership/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_PERSON: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/person/:${RouteParam.PERSON_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_PERSON_CONFIRMATION: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/person_confirmation/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_BUSINESS: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/business/:${RouteParam.BUSINESS_APPLICATION_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_USAGE: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/usage/:${RouteParam.AMPLIFY_APPLICATION_ID}`,
  V2_SIGN_UP_AMPLIFY_APP_DISCLOSURES: `/${RouteName.V2_SIGN_UP}/amplify/apply/:${RouteParam.BUSINESS_ID}/disclosures/:${RouteParam.AMPLIFY_APPLICATION_ID}`
}
